import logo from './logo.svg';
import discord from './Discord-Logo-Color.svg';
import twitter from './twitter.svg';
import './App.css';

import { Grid, Typography } from '@mui/material';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>PawFlix</h1>
        <img src={logo} className="App-logo" alt="logo" />
        <a href="https://discord.gg/tvqKdZQmjC" target="_blank" rel="noreferrer">
          <Typography>News/Announcements</Typography>
        </a>
      </header>
    </div>
  );
}

export default App;
